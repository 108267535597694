@layer base {
    :root {
        --editor-line-height: 24px;
    }
}

#lexical-editor {
    .editor-input {
        min-height: 450px;
        max-width: 680px;
        resize: none;
        font-size: 16px;
        line-height: var(--editor-line-height);
        letter-spacing: 1px;
        font-weight: 300;
        position: relative;
        tab-size: 1;
        outline: 0;
        padding: 15px 10px;
        caret-color: #444;
    }

    .editor-text-bold {
        font-weight: bold;
    }

    .editor-link {
        color: rgb(33, 111, 219);
        text-decoration: none;
    }

    .editor-paragraph {
        margin: 0 0 10px;
        position: relative;
    }

    .editor-paragraph:last-child {
        margin-bottom: 0;
    }

    .editor-heading-h1 {
        font-size: 24px;
        line-height: 28px;
        color: rgb(5, 5, 5);
        font-weight: 600;
        margin: 0 0 12px;
        padding: 0;
    }

    h2 {
        font-size: 22px;
        font-weight: 600;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
    }

    .editor-quote {
        margin: 0;
        font-size: 15px;
        color: rgb(101, 103, 107);
        border-left-color: rgb(206, 208, 212);
        border-left-width: 4px;
        border-left-style: solid;
        padding-left: 16px;
    }

    .editor-list-ol {
        padding: 0;
        margin: 0 0 0 16px;
    }

    .editor-list-ul {
        padding: 0;
        margin: 0 0 0 16px;
    }

    .editor-listitem {
        list-style: unset;
        margin: 8px 32px 8px 32px;
    }

    .editor-nested-listitem {
        list-style-type: none;
    }

    .editor-image {
        text-align: center;
        outline: none;

        > div > img {
        cursor: pointer;
        transition: opacity 100ms ease-in;

        &:hover {
            opacity: 0.7;
        }
        
        &.focused {
            transition: opacity 100ms ease-in, outline 50ms ease-in;
            border-radius: 5px;
            outline: 4px solid #2d6aba;
            
            &:hover {
            opacity: 0.7;
            }
        }
        }

    }
    }