.rc-calendar-input-wrap {
  display: none;
}

.rc-calendar-year-panel-header > a {
  color: #4a4a4a;
}

.rc-calendar-month-panel-header > a {
  color: #4a4a4a;
}

.rc-calendar-header > * > a {
  color: #4a4a4a;
}