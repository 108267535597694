.CalendarDay__default {
  border: none;
}

.CalendarDay {
  font-size : 0.9rem;
  outline   : none;
}

.CalendarDay__blocked_calendar {
  background : unset;
  color      : #d7d7d7;
}

.CalendarDay__blocked_calendar:hover {
  background : unset;
  color      : #d7d7d7;
  border     : unset;
}

.DayPickerNavigation {
  display: none;
}

.DayPicker__horizontal {
  background : unset;
}
