@layer base {
    html {
        height: 100%;
        font-family: 'Hiragino Sans', sans-serif;
        font-size: 80%;
        color: #4a4a4a;
        background-color: #f5f5f5;
      }
    
      body {
        height: 100%;
      }
    
      ::selection {
        color: #fff;
        background-color: #49c0ad;
      }
    
      /* stylelint-disable */
      #__next, #root {
        height: 100%;
      }
    
      a {
        text-decoration: none;
        transition: 0.3s;
      }
    
      ::placeholder {
        color: #c7c7c7;
      }
    
      li {
        list-style-type: none;
      }
    
      pre::-webkit-scrollbar {
        background: transparent;
        width: 10px;
      }
    
      pre::-webkit-scrollbar-thumb {
        background: #999;
      }

      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
          monospace;
      }
}
